import React from "react";
import {
  Box,
  Flex,
  Heading,
  Image,
  Button,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import "./header.css";
import { Element } from "react-scroll"; // Import Element from react-scroll

const Header = () => {
  const displayImage = useBreakpointValue({ base: "none", lg: "block" });

  return (
    <>
      <Element name="home" style={{ marginTop: "30px" }}>
        <Flex
          direction={{ base: "column", md: "row" }}
          align={{ base: "center", md: "flex-start" }}
          justify="space-between"
          p={4}
          ml={{ base: 0, md: 75 }}
        >
          {/* Text on the left */}
          <Box mt={{ base: 25, md: 100 }}>
            <Heading as="h1" size={{ base: "1xl", md: "lg" }} color="red">
              BOOKING TIME
            </Heading>
            <Text
              fontSize={{ base: "4xl", md: "5xl" }}
              mt={2}
              width={{ base: "100%", md: "500px" }}
            >
              Parteneri de incredere pentru orice destinatie
            </Text>
            <Text fontSize="lg" mt={2} color="gray">
              Cele mai bune solutii si parteneriate pentru turism B2B
            </Text>

            <Button
              padding={6}
              mt={5}
              href="https://www.youtube.com/watch?v=oLqJSHP4ohQ" // Replace with your YouTube link
              target="_blank"
              rel="noopener noreferrer"
              letterSpacing={1}
              variant="outline"
              mr={4}
              color="white"
              bg="red.500"
              borderRadius="25px"
              as="a"
              textTransform="uppercase"
              size="md"
              fontWeight="bold"
              px={2}
              _hover={{
                boxShadow: "4px 4px rgba(255, 99, 71,0.9)",
              }}
            >
              <Text position="relative" zIndex={1}>
                VIDEO PREZENTARE
              </Text>
            </Button>
          </Box>

          {/* Image on the right */}
          <Box
            display={displayImage}
            mt={{ base: 4, md: 0 }}
            ml={{ base: 0, md: 50 }}
          >
            <Image
              src="https://www.bookingtime.eu/assets/img/misc/hero-laptop.png"
              alt="Header Image"
              borderRadius="md"
              width={{ base: "100%", md: "850px" }}
            />
          </Box>
          {/* Video on the right */}
        </Flex>
      </Element>
    </>
  );
};

export default Header;
