import React from "react";
import {
  Box,
  Container,
  Flex,
  Heading,
  Text,
  Button,
  VStack,
} from "@chakra-ui/react";
import { Link } from "react-scroll"; // Import Link from react-scroll

const PresentationSection = () => {
  return (
    <Box
      id="presentation"
      pt={90}
      pb={90}
      bgImage="url('assets/img/bg/bg-77.svg')"
      bgRepeat="no-repeat"
      bgPosition="500% 0%"
    >
      <Container maxW="container.xl">
        <Flex
          direction={{ base: "column", md: "row" }}
          flexWrap="wrap"
          alignItems="center"
        >
          <Box
            mb={{ base: 30, md: 0 }}
            flex="1"
            data-custom-animations="true"
            data-ca-options='{"triggerHandler":"inview", "animationTarget":"all-childs", "duration":"1600", "delay":"160", "easing":"ease", "direction":"forward", "initValues":{"translateY":30, "opacity":0}, "animations":{"translateY":0, "opacity":1}}'
          >
            <Box mb={55} pr={{ md: 4 }}>
              <Heading as="h6" textTransform="uppercase">
                Plata
              </Heading>
              <Heading as="h2">
                <strong>Cu Booking Time</strong> distributia poate fi
                administrata in diferite moduri
              </Heading>
            </Box>
            <Link to="formContact" smooth={true} duration={500}>
              <Button
                as="a"
                href="#contact-us"
                textTransform="uppercase"
                size="md"
                cursor="pointer"
                variant="outline"
                fontWeight="bold"
                px={2}
                data-localscroll="true"
                position="relative"
                overflow="hidden"
                _before={{
                  content: '""',
                  position: "absolute",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  background: "linear-gradient(to right, #f42958, #f42958)",
                  zIndex: -1,
                  transition: "transform 0.3s ease",
                  transform: "scaleX(0)",
                  transformOrigin: "left",
                }}
                _hover={{
                  _before: {
                    transform: "scaleX(1)",
                  },
                }}
              >
                <Text position="relative" zIndex={1}>
                  Afla mai multe
                </Text>
              </Button>
            </Link>
          </Box>

          <Box
            flex={{ md: "0 0 35%" }}
            ml={{ md: 4 }}
            data-parallax="true"
            data-parallax-options='{"duration":"2000", "easing":"linear", "reverse":true, "triggerHook":"onEnter", "overflowHidden": false}'
            data-parallax-from='{"translateY":-30}'
            data-parallax-to='{"translateY":120}'
          >
            <VStack spacing={10} align="start">
              <Box className="iconbox" shadow="md" p={6} borderRadius="lg">
                <Box className="iconbox-icon-wrap" mb={4}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="64"
                    height="64"
                    viewBox="0 0 64 64"
                  >
                    <title>64px_credit-card-in</title>
                    <g fill="#000000" stroke="#f42958">
                      <path
                        fill="none"
                        strokeWidth="2"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        strokeLinejoin="miter"
                        d="M20 60V10"
                      ></path>
                      <path
                        fill="none"
                        strokeWidth="2"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        strokeLinejoin="miter"
                        d="M30 60V10"
                      ></path>
                      <path
                        fill="none"
                        strokeWidth="2"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        strokeLinejoin="miter"
                        d="M44 52V40"
                      ></path>
                      <path
                        fill="none"
                        strokeWidth="2"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        strokeLinejoin="miter"
                        d="M44 20v5"
                      ></path>
                      <path
                        fill="none"
                        stroke="#f42958"
                        strokeWidth="2"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        d="M54 10v46a4 4 0 0 1-4 4H14a4 4 0 0 1-4-4V10h44z"
                        strokeLinejoin="miter"
                      ></path>
                      <path
                        fill="none"
                        stroke="#f42958"
                        strokeWidth="2"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        d="M10 16H8A6 6 0 1 1 8 4h48a6 6 0 1 1 0 12h-2"
                      ></path>
                    </g>
                  </svg>
                </Box>
                <Box className="contents">
                  <Heading as="h3" size="md">
                    Credit
                  </Heading>
                  <Text fontSize={{ base: "2xl", md: "xl" }}>
                    Poti rezerva servicii si emite voucherul in limita
                    creditului, fara a plati.
                  </Text>
                </Box>
              </Box>

              <Box className="iconbox" shadow="md" p={6} borderRadius="lg">
                <Box className="iconbox-icon-wrap" mb={4}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="64"
                    height="64"
                    viewBox="0 0 64 64"
                  >
                    <title>64px_payment</title>
                    <g fill="#000000" stroke="#f42958">
                      <path
                        fill="none"
                        stroke="#f42958"
                        strokeWidth="2"
                        strokeMiterlimit="10"
                        strokeLinejoin="miter"
                        strokeLinecap="butt"
                        d="M36.478 42H4V16h56v26h-6"
                      ></path>
                      <path
                        fill="none"
                        stroke="#f42958"
                        strokeWidth="2"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        d="M16 16v-6a4 4 0 0 1 8 0v6"
                      ></path>
                      <path
                        fill="none"
                        stroke="#f42958"
                        strokeWidth="2"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        d="M24 16V8a4 4 0 0 1 8 0v8"
                      ></path>
                      <path
                        fill="none"
                        stroke="#f42958"
                        strokeWidth="2"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        d="M32 16V6a4 4 0 0 1 8 0v10"
                      ></path>
                      <path
                        fill="none"
                        stroke="#f42958"
                        strokeWidth="2"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        d="M40 16V8a4 4 0 0 1 8 0v8"
                      ></path>
                      <circle
                        fill="none"
                        strokeWidth="2"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        cx="32"
                        cy="29"
                        r="5"
                        strokeLinejoin="miter"
                      ></circle>
                      <path
                        fill="none"
                        strokeWidth="2"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        strokeLinejoin="miter"
                        d="M20 24h-8"
                      ></path>
                      <path
                        fill="none"
                        stroke="#f42958"
                        strokeWidth="2"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        d="M16 42v1c0 10.493 8.507 19 19 19s19-8.507 19-19V24c-4.519 0-8 3.481-8 8v6c-6.167 0-12 4.333-12 10"
                      ></path>
                    </g>
                  </svg>
                </Box>
                <Box className="contents">
                  <Heading as="h3" size="md">
                    Plata anticipata
                  </Heading>
                  <Text fontSize={{ base: "2xl", md: "xl" }}>
                    Poti face rezervari, dar daca rezervarea nu este platita si
                    confirmata, aceasta se anuleaza automat inainte de inceperea
                    perioadei de penalizare;
                  </Text>
                </Box>
              </Box>

              <Box className="iconbox" shadow="md" p={6} borderRadius="lg">
                <Box className="iconbox-icon-wrap" mb={4}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="64"
                    height="64"
                    viewBox="0 0 64 64"
                  >
                    <title>64px_tool-blur</title>
                    <g fill="#000000" stroke="#f42958">
                      <circle
                        cx="22"
                        cy="22"
                        r="6"
                        fill="none"
                        stroke="#f42958"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                      ></circle>
                      <circle
                        cx="22"
                        cy="5"
                        r="3"
                        fill="none"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                      ></circle>
                      <circle
                        cx="5"
                        cy="22"
                        r="3"
                        fill="none"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                      ></circle>
                      <circle
                        cx="5"
                        cy="42"
                        r="3"
                        fill="none"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                      ></circle>
                      <circle
                        cx="59"
                        cy="22"
                        r="3"
                        fill="none"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                      ></circle>
                      <circle
                        cx="59"
                        cy="42"
                        r="3"
                        fill="none"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                      ></circle>
                      <circle
                        cx="42"
                        cy="5"
                        r="3"
                        fill="none"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                      ></circle>
                      <circle
                        cx="22"
                        cy="59"
                        r="3"
                        fill="none"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                      ></circle>
                      <circle
                        cx="42"
                        cy="59"
                        r="3"
                        fill="none"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                      ></circle>
                      <circle
                        cx="42"
                        cy="22"
                        r="6"
                        fill="none"
                        stroke="#f42958"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                      ></circle>
                      <circle
                        cx="22"
                        cy="42"
                        r="6"
                        fill="none"
                        stroke="#f42958"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                      ></circle>
                      <circle
                        cx="42"
                        cy="42"
                        r="6"
                        fill="none"
                        stroke="#f42958"
                        strokeLinecap="square"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                      ></circle>
                    </g>
                  </svg>
                </Box>
                <Box className="contents">
                  <Heading as="h3" size="md">
                    Mixt
                  </Heading>
                  <Text fontSize={{ base: "2xl", md: "xl" }}>
                    O combinatie a modurilor Credit si Plata anticipata.
                  </Text>
                </Box>
              </Box>
            </VStack>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

export default PresentationSection;
