import React from "react";
import {
  Box,
  Container,
  SimpleGrid,
  Heading,
  Text,
  Flex,
  Icon,
} from "@chakra-ui/react";
import {
  FaGlobe,
  FaLeaf,
  FaRocket,
  FaCogs,
  FaMoneyBillWave,
} from "react-icons/fa";
import { Element } from "react-scroll"; // Import Element from react-scroll

const Objectives = () => {
  return (
    <Element name="objectives" style={{ marginTop: "30px" }}>
      <Box py={{ base: 10, md: 20 }}>
        <Container maxW="container.xl">
          <Box textAlign="center" mb={12}>
            <Heading
              pt="3t0px"
              as="h6"
              size="xs"
              textTransform="uppercase"
              mb={2}
            >
              Obiectivele Noastre
            </Heading>
            <Heading as="h2">
              <strong>Solutii si parteneriate</strong> pentru un turism de
              calitate
            </Heading>
          </Box>

          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
            <Box textAlign="center" px={3}>
              <Flex justifyContent="center" mb={4}>
                <Icon as={FaGlobe} w={12} h={12} color="#ffb09f" />
              </Flex>
              <Heading as="h3" size="md" mb={2}>
                Eficienta
              </Heading>
              <Text>Oferim solutii in timp real.</Text>
            </Box>

            <Box textAlign="center" px={3}>
              <Flex justifyContent="center" mb={4}>
                <Icon as={FaLeaf} w={12} h={12} color="#ad78ef" />
              </Flex>
              <Heading as="h3" size="md" mb={2}>
                Flexibilitate
              </Heading>
              <Text>
                Un sistem dinamic de oferte B2B, adaptat fiecarei situatii.
              </Text>
            </Box>

            <Box textAlign="center" px={3}>
              <Flex justifyContent="center" mb={4}>
                <Icon as={FaRocket} w={12} h={12} color="#3ed2a7" />
              </Flex>
              <Heading as="h3" size="md" mb={2}>
                Servicii de calitate
              </Heading>
              <Text>
                Parteneriatele noastre si certificarile sunt cea mai buna
                dovada.
              </Text>
            </Box>

            <Box
              textAlign="center"
              px={3}
              gridColumn={{ base: "span 1", md: "span 2" }}
            >
              <Flex justifyContent="center" mb={4}>
                <Icon as={FaCogs} w={12} h={12} color="#edba57" />
              </Flex>
              <Heading as="h3" size="md" mb={2}>
                Tehnologie
              </Heading>
              <Text>
                Tehnologie de ultima ora, pentru a face fata rapid si eficient
                intregului proces.
              </Text>
            </Box>

            <Box textAlign="center" px={3}>
              <Flex justifyContent="center" mb={4}>
                <Icon as={FaMoneyBillWave} w={12} h={12} color="#577eed" />
              </Flex>
              <Heading as="h3" size="md" mb={2}>
                Pret corect
              </Heading>
              <Text>
                Transparenta si adaptare pentru orice tip de parteneriate.
              </Text>
            </Box>
          </SimpleGrid>
        </Container>
      </Box>
    </Element>
  );
};

export default Objectives;
